import { isIOS, isMobile, isSafari, isChrome, isTablet, osVersion } from 'react-device-detect';

export default class DeviceManager {
  static isMobile = (): boolean => isMobile;

  static isTablet = (): boolean => isTablet;

  static isSafari = (): boolean => isSafari;

  static isChrome = (): boolean => isChrome;

  static isIOS = (): boolean => isIOS;

  static osVersion = (): string => osVersion;

  static supportsTouch = (): boolean => 'ontouchstart' in document;

  static isHorizontalMobileView = () => window.innerWidth > window.innerHeight && DeviceManager.isMobile();


}
