import { alpha, Theme } from '@mui/material';

export default function Paper({ palette }: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },

      styleOverrides: {
        root: {
          background: alpha(palette.common.black, 0.5),
          backdropFilter: 'blur(15px)',
        },
      },
    },
  };
}
