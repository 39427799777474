import { allowUsesCookie } from '../common/CookieConsent';

function shortenString(str: string) {
  if (!str) return str;
  if (str.length > 100) {
    return `${str.substring(0, 97)}...`;
  }
  return str;
}

export const sendToGA = (type: string, obj: any = null): void => {
  const w = window as any;
  const scripts = document.getElementsByTagName('script');
  let gaId = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < scripts.length; i++) {
    const match = scripts[i].innerHTML.match(/'G-([\w-]+)'/);
    if (match) {
      gaId = match[0].replace(/'/g, '');
      break;
    }
  }

  if (gaId.startsWith('G-') && w.gtag) {
    w.gtag('event', obj.eventAction, {
      event_category: obj.eventCategory,
      event_label: shortenString(obj.eventLabel),
      ...(obj.eventId && gaId === 'G-R2FVP1NTYY' && { event_id: obj.eventId }),
      ...(obj.eventType && { event_type: obj.eventType }),
    });
  }

  if (gaId.startsWith('UA-') && w.ga) {
    w.ga('userViewId.send', type, obj);
  }
};

export const createGA = ({ id, customName }: { id: string; customName?: string }): void => {
  const w = window as any;
  if (id.startsWith('G-')) {
    w.gtag('js', new Date());
    w.gtag('config', id);
  }
  if (id.startsWith('UA-') && customName) {
    w.ga('create', id, { name: customName, cookieFlags: 'secure;samesite=none' });
    w.ga('send', 'pageview');
  }
};

export const startGA = (tour: any = null, loaded: boolean, cookie: boolean = null): void => {
  if (cookie === null) cookie = allowUsesCookie(tour?.hideCookie);
  if (!tour || !loaded || !cookie) return;
  const googleViewId = tour.client?.googleViewId || tour.user?.googleViewId;
  if (googleViewId) createGA({ id: googleViewId, customName: 'userViewId' });
  createGA({ id: 'G-R2FVP1NTYY' }); // default treedis ga4
};
