import { Theme } from '@mui/material/styles';
import { merge } from 'lodash';

import Accordion from './Accordion';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Checkbox from './Checkbox';
import Chip from './Chip';
import Fab from './Fab';
import IconButton from './IconButton';
import Paper from './Paper';
import Tab from './Tab';
import Tabs from './Tabs';
import TextField from './TextField';
import Slider from './Slider';
import ListItemButton from './ListItemButton';

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Accordion(theme),
    Paper(theme),
    Button(theme),
    ButtonGroup(theme),
    Fab(theme),
    Tab(theme),
    Tabs(theme),
    Slider(theme),
    Chip(theme),
    Checkbox(theme),
    IconButton(),
    TextField(theme),
    ListItemButton(theme),
  );
}
